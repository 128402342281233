import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaMobileAlt, FaTv, FaMoneyBillWave, FaHistory, FaWallet, FaChartBar } from 'react-icons/fa';

const HomeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.background};
`;

const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(145deg, #6a11cb 0%, #2575fc 100%);
  color: white;
  width: 150px;
  height: 150px;
  margin: 1rem;
  text-decoration: none;
  border-radius: 16px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  svg {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }

  span {
    font-size: 1rem;
    font-weight: bold;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 120px;
    height: 120px;

    svg {
      font-size: 2rem;
    }

    span {
      font-size: 0.9rem;
    }
  }
`;

const Home = () => {
  return (
    <HomeWrapper>
      <Card to="/mobile-recharge">
        <FaMobileAlt />
        <span>Mobile Recharge</span>
      </Card>
      <Card to="/dth-recharge">
        <FaTv />
        <span>DTH Recharge</span>
      </Card>
      <Card to="/add-money">
        <FaMoneyBillWave />
        <span>Add Money</span>
      </Card>
      <Card to="/recharge-history">
        <FaHistory />
        <span>Recharge History</span>
      </Card>
      <Card to="/wallet-history">
        <FaWallet />
        <span>Wallet History</span>
      </Card>
      <Card to="/commission">
        <FaChartBar />
        <span>Commission</span>
      </Card>
    </HomeWrapper>
  );
};

export default Home;
