import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaHome, FaUser } from 'react-icons/fa';

const FooterWrapper = styled.footer`
  background: ${({ theme }) => theme.colors.secondary};
  padding: 1rem;
  color: white;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    color: white;
    font-size: 1.5rem;
    margin: 0 1rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    a {
      font-size: 1.2rem;
    }
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <Link to="/home"><FaHome /></Link>
      <Link to="/profile"><FaUser /></Link>
    </FooterWrapper>
  );
};

export default Footer;
