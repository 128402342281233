import React from 'react';
import styled from 'styled-components';
import { FaSignOutAlt } from 'react-icons/fa';

const HeaderWrapper = styled.header`
  background: ${({ theme }) => theme.colors.primary};
  padding: 0.5rem 1rem;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 1rem;
    padding: 0.5rem;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 1.2rem;
  }
`;

const Balance = styled.div`
  font-size: 1rem;
  font-weight: bold;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 0.9rem;
  }
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 1.2rem;
  }
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <Title>PV Recharges</Title>
      <Balance>Balance: ₹1000.90</Balance>
      <LogoutButton>
        <FaSignOutAlt />
      </LogoutButton>
    </HeaderWrapper>
  );
};

export default Header;
