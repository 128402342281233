import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link from react-router-dom
import { toast, ToastContainer } from 'react-toastify'; // For displaying notifications
import 'react-toastify/dist/ReactToastify.css'; // Default styles for react-toastify
import styled from 'styled-components';
import Select from 'react-select';

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  padding: 1rem;
  box-sizing: border-box;
`;

const FormWrapper = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
  }
`;

const Title = styled.h2`
  margin-bottom: 1.5rem;
  text-align: center;
  color: #333;
  font-size: 1.8rem;
  font-weight: 600;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: 500;
  color: #666;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.2s ease;

  &:focus {
    border-color: #2575fc;
    outline: none;
  }
`;

const SubmitButton = styled.button`
  padding: 0.75rem 1.25rem;
  background-color: #2575fc;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #6a11cb;
  }

  &:active {
    background-color: #4a90e2;
  }
`;

const LoginLink = styled.div`
  margin-top: 1.5rem;
  text-align: center;
  font-size: 1rem;
  color: #666;

  a {
    color: #2575fc;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const accountTypes = [
  { value: 'Retailer', label: 'Retailer' },
  { value: 'Distributor', label: 'Distributor' },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    padding: '0.75rem',
    border: '1px solid #ddd',
    borderRadius: '6px',
    fontSize: '1rem',
    boxShadow: '0 0 0 1px #ddd inset',
    transition: 'box-shadow 0.2s ease',
    '&:hover': {
      boxShadow: '0 0 0 2px #2575fc inset',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  option: (provided) => ({
    ...provided,
    padding: '0.75rem',
    fontSize: '1rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '1rem',
  }),
};

const Signup = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    username: '',
    address: '',
    pinCode: '',
    mobileNumber: '',
    email: '',
    state: '',
    companyName: '',
    accountType: null,
    password: '', // Added password field
  });

  const navigate = useNavigate(); // Hook for navigation

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData(prevData => ({
      ...prevData,
      accountType: selectedOption ? selectedOption.value : '', // Set only the value, not the whole object
    }));
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
  
    fetch('http://localhost:3001/api/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(response => response.json())
    .then(data => {
      if (data.message === 'User registered successfully') {
        toast.success('Registration successful! Redirecting to login...');
        setTimeout(() => {
          navigate('/login'); // Redirect to login page after successful registration
        }, 2000); // Delay to allow user to see the success message
      } else {
        toast.error(data.message || 'An error occurred');
      }
    })
    .catch(error => toast.error('An error occurred: ' + error));
  };

  return (
    <PageWrapper>
      <FormWrapper>
        <Title>Signup</Title>
        <Form onSubmit={handleSubmit}>
          <Label htmlFor="fullName">Full Name</Label>
          <Input
            type="text"
            id="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
          />

          <Label htmlFor="username">Username</Label>
          <Input
            type="text"
            id="username"
            value={formData.username}
            onChange={handleChange}
            required
          />

          <Label htmlFor="address">Address</Label>
          <Input
            type="text"
            id="address"
            value={formData.address}
            onChange={handleChange}
            required
          />

          <Label htmlFor="pinCode">PIN Code</Label>
          <Input
            type="text"
            id="pinCode"
            value={formData.pinCode}
            onChange={handleChange}
            required
          />

          <Label htmlFor="mobileNumber">Mobile Number</Label>
          <Input
            type="text"
            id="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleChange}
            required
          />

          <Label htmlFor="email">Email ID</Label>
          <Input
            type="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <Label htmlFor="state">State</Label>
          <Input
            type="text"
            id="state"
            value={formData.state}
            onChange={handleChange}
            required
          />

          <Label htmlFor="companyName">Company Name</Label>
          <Input
            type="text"
            id="companyName"
            value={formData.companyName}
            onChange={handleChange}
            required
          />

          <Label htmlFor="accountType">Select Account Type</Label>
          <Select
            id="accountType"
            options={accountTypes}
            value={accountTypes.find(option => option.value === formData.accountType)}
            onChange={handleSelectChange}
            placeholder="Select Account Type"
            styles={customStyles}
            required
          />

          <Label htmlFor="password">Password</Label>
          <Input
            type="password"
            id="password"
            value={formData.password}
            onChange={handleChange}
            required
          />

          <SubmitButton type="submit">Register</SubmitButton>

          <LoginLink>
            Already have an account? <Link to="/login">Login here</Link>
          </LoginLink>
        </Form>
      </FormWrapper>
      <ToastContainer /> {/* ToastContainer to display notifications */}
    </PageWrapper>
  );
};

export default Signup;
