import React, { useState } from 'react';
import styled from 'styled-components';

// Table styles
const TableWrapper = styled.div`
  padding: 1rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem; /* Adjusted font size */
  color: #333;
`;

const TableHeader = styled.th`
  background-color: #2575fc;
  color: white;
  padding: 0.75rem;
  text-align: left;
  font-weight: bold;
  font-size: 0.875rem; /* Adjusted font size */
`;

const TableCell = styled.td`
  padding: 0.75rem;
  border-bottom: 1px solid #ddd;
  font-size: 0.875rem; /* Adjusted font size */
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #f1f1f1;
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
  font-size: 1.5rem; /* Adjusted title font size */
  font-weight: 600;
`;

const SearchBar = styled.input`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.875rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

const PageButton = styled.button`
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #2575fc;
  color: white;
  font-size: 0.875rem;
  cursor: pointer;
  margin: 0 0.25rem;

  &:hover {
    background-color: #6a11cb;
  }
`;

const RechargeHistory = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 20;

  // Example data
  const rechargeData = [
    { id: 1, userDetails: 'John Doe', orderId: 'ORD123', number: '9876543210', operator: 'Airtel', amount: '$50', balance: '$500', refId: 'REF123', response: 'Success', status: 'Completed' },
    { id: 2, userDetails: 'Jane Smith', orderId: 'ORD124', number: '9876543211', operator: 'Jio', amount: '$30', balance: '$470', refId: 'REF124', response: 'Success', status: 'Pending' },
    // Add more data as needed
  ];

  const filteredData = rechargeData.filter(record =>
    (record.userDetails.toLowerCase().includes(searchTerm.toLowerCase()) ||
    record.orderId.toLowerCase().includes(searchTerm.toLowerCase()) ||
    record.number.includes(searchTerm)) &&
    (statusFilter === 'All' || record.status === statusFilter)
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  const totalPages = Math.ceil(filteredData.length / recordsPerPage);

  return (
    <TableWrapper>
      <Title>Recharge History</Title>
      <FilterWrapper>
        <SearchBar
          type="text"
          placeholder="Search by Number"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        
      </FilterWrapper>
      <Table>
        <thead>
          <tr>
            <TableHeader>USER DETAILS</TableHeader>
            <TableHeader>ORDER ID</TableHeader>
            <TableHeader>NUMBER</TableHeader>
            <TableHeader>OPERATOR</TableHeader>
            <TableHeader>AMOUNT</TableHeader>
            <TableHeader>BALANCE</TableHeader>
            <TableHeader>REF ID</TableHeader>
            <TableHeader>RESPONSE</TableHeader>
            <TableHeader>STATUS</TableHeader>
            <TableHeader>ACTION</TableHeader>
          </tr>
        </thead>
        <tbody>
          {paginatedData.length > 0 ? (
            paginatedData.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.userDetails}</TableCell>
                <TableCell>{item.orderId}</TableCell>
                <TableCell>{item.number}</TableCell>
                <TableCell>{item.operator}</TableCell>
                <TableCell>{item.amount}</TableCell>
                <TableCell>{item.balance}</TableCell>
                <TableCell>{item.refId}</TableCell>
                <TableCell>{item.response}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell><button>DISPUTE</button></TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan="10">No data available in table</TableCell>
            </TableRow>
          )}
        </tbody>
      </Table>
      <PaginationWrapper>
        <PageButton
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </PageButton>
        <span>Showing {((currentPage - 1) * recordsPerPage) + 1} to {Math.min(currentPage * recordsPerPage, filteredData.length)} of {filteredData.length} entries</span>
        <PageButton
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
        </PageButton>
      </PaginationWrapper>
    </TableWrapper>
  );
};

export default RechargeHistory;
