import React from 'react';
import styled from 'styled-components';

// Table styles
const TableWrapper = styled.div`
  padding: 1rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem; /* Reduced font size */
  color: #333;
`;

const TableHeader = styled.th`
  background-color: #2575fc;
  color: white;
  padding: 0.75rem; /* Adjusted padding */
  text-align: left;
  font-weight: bold;
  font-size: 0.875rem; /* Reduced font size */
`;

const TableCell = styled.td`
  padding: 0.75rem; /* Adjusted padding */
  border-bottom: 1px solid #ddd;
  font-size: 0.875rem; /* Reduced font size */
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #f1f1f1;
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
  font-size: 1.5rem; /* Reduced title font size */
  font-weight: 600;
`;

const Commission = () => {
  const commissionData = [
    { id: 1, type: 'Recharge', operator: 'Airtel', percentage: '5%' },
    { id: 2, type: 'DTH', operator: 'Dish TV', percentage: '3%' },
    { id: 3, type: 'Recharge', operator: 'Jio', percentage: '4%' },
    // Add more data as needed
  ];

  return (
    <TableWrapper>
      <Title>Commission Details</Title>
      <Table>
        <thead>
          <tr>
            <TableHeader>S.NO</TableHeader>
            <TableHeader>Type</TableHeader>
            <TableHeader>Operator</TableHeader>
            <TableHeader>Percentage</TableHeader>
          </tr>
        </thead>
        <tbody>
          {commissionData.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.id}</TableCell>
              <TableCell>{item.type}</TableCell>
              <TableCell>{item.operator}</TableCell>
              <TableCell>{item.percentage}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

export default Commission;
