import React from 'react';
import styled from 'styled-components';

// Table styles
const TableWrapper = styled.div`
  padding: 1rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem; /* Adjusted font size */
  color: #333;
`;

const TableHeader = styled.th`
  background-color: #2575fc;
  color: white;
  padding: 0.75rem;
  text-align: left;
  font-weight: bold;
  font-size: 0.875rem; /* Adjusted font size */
`;

const TableCell = styled.td`
  padding: 0.75rem;
  border-bottom: 1px solid #ddd;
  font-size: 0.875rem; /* Adjusted font size */
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:hover {
    background-color: #f1f1f1;
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
  font-size: 1.5rem; /* Adjusted title font size */
  font-weight: 600;
`;

const WalletHistory = () => {
  const walletData = [
    { id: 1, userDetails: 'John Doe', txnType: 'Credit', txnMode: 'Bank Transfer', amount: '$100', openingBalance: '$500', closingBalance: '$600', status: 'Completed', txnDetails: 'Added funds' },
    { id: 2, userDetails: 'Jane Smith', txnType: 'Debit', txnMode: 'Credit Card', amount: '$50', openingBalance: '$600', closingBalance: '$550', status: 'Completed', txnDetails: 'Purchase' },
    { id: 3, userDetails: 'Alice Johnson', txnType: 'Credit', txnMode: 'PayPal', amount: '$200', openingBalance: '$550', closingBalance: '$750', status: 'Pending', txnDetails: 'Refund' },
    // Add more data as needed
  ];

  return (
    <TableWrapper>
      <Title>Wallet History</Title>
      <Table>
        <thead>
          <tr>
            <TableHeader>Users Details</TableHeader>
            <TableHeader>Txn Type</TableHeader>
            <TableHeader>Txn Mode</TableHeader>
            <TableHeader>Amount</TableHeader>
            <TableHeader>Opening Balance</TableHeader>
            <TableHeader>Closing Balance</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader>Txn Details</TableHeader>
          </tr>
        </thead>
        <tbody>
          {walletData.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.userDetails}</TableCell>
              <TableCell>{item.txnType}</TableCell>
              <TableCell>{item.txnMode}</TableCell>
              <TableCell>{item.amount}</TableCell>
              <TableCell>{item.openingBalance}</TableCell>
              <TableCell>{item.closingBalance}</TableCell>
              <TableCell>{item.status}</TableCell>
              <TableCell>{item.txnDetails}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

export default WalletHistory;
