import React, { useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import axios from 'axios';

const PageWrapper = styled.div`
  padding: 2rem;
  background-color: #f9f9f9;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const FormWrapper = styled.div`
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Title = styled.h2`
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: bold;
  color: #555;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1rem;
  outline: none;
  transition: border 0.2s;

  &:focus {
    border-color: #007bff;
  }
`;

const SubmitButton = styled.button`
  padding: 0.75rem 1rem;
  background-color: #007bff;
  margin-bottom: 30px;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    padding: '0.5rem',
    border: '1px solid #ccc',
    borderRadius: '6px',
    fontSize: '1rem',
    outline: 'none',
    transition: 'border 0.2s',

    '&:focus': {
      borderColor: '#007bff',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const operators = [
  { value: 'ARF', label: 'AIRTEL' },
  { value: 'BL', label: 'BSNL' },
  { value: 'JO', label: 'JIO' },
  { value: 'BST', label: 'BSNL TOPUP' },
  { value: 'VDF', label: 'VI' },
  { value: 'ART', label: 'AIRTEL MONEY' },
  { value: 'I', label: 'Idea' },
  { value: 'GPL', label: 'G PLAY REDEEM' },
  { value: 'BSNLSP', label: 'BSNL SP 24X7' },
  { value: 'BSNLTP', label: 'BSNL TP 24X7' },
  { value: 'JIOLIVE', label: 'JIO LIVE' },
];

const circles = [
  { value: 1, label: 'Andaman & Nicobar Islands' },
  { value: 2, label: 'Andhra Pradesh' },
  { value: 3, label: 'Arunachal Pradesh' },
  { value: 4, label: 'Assam' },
  { value: 5, label: 'Bihar' },
  { value: 6, label: 'Chandigarh' },
  { value: 7, label: 'Chattisgarh' },
  { value: 8, label: 'Dadra & Nagar Haveli' },
  { value: 9, label: 'Daman & Diu' },
  { value: 10, label: 'Delhi' },
  { value: 11, label: 'Goa' },
  { value: 12, label: 'Gujarat' },
  { value: 13, label: 'Haryana' },
  { value: 14, label: 'Himachal Pradesh' },
  { value: 15, label: 'Jammu & Kashmir' },
  { value: 16, label: 'Jharkhand' },
  { value: 17, label: 'Karnataka' },
  { value: 18, label: 'Kerala' },
  { value: 38, label: 'Kolkata' },
  { value: 19, label: 'Lakshadweep' },
  { value: 20, label: 'Madhya Pradesh' },
  { value: 21, label: 'Maharashtra' },
  { value: 22, label: 'Manipur' },
  { value: 23, label: 'Meghalaya' },
  { value: 24, label: 'Mizoram' },
  { value: 39, label: 'Mumbai' },
  { value: 25, label: 'Nagaland' },
  { value: 33, label: 'North East' },
  { value: 26, label: 'Odisha' },
  { value: 27, label: 'Poducherry' },
  { value: 28, label: 'Punjab' },
  { value: 29, label: 'Rajasthan' },
  { value: 30, label: 'Sikkim' },
  { value: 31, label: 'Tamil Nadu' },
  { value: 32, label: 'Telangana' },
  { value: 34, label: 'Uttar Pradesh East' },
  { value: 37, label: 'Uttar Pradesh West' },
  { value: 35, label: 'Uttarakhand' },
  { value: 36, label: 'West Bengal' },
];

const MobileRecharge = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [operator, setOperator] = useState(null);
  const [planAmount, setPlanAmount] = useState('');
  const [circle, setCircle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!mobileNumber || !operator || !planAmount || !circle) {
      alert('Please fill in all the fields.');
      return;
    }

    setLoading(true);
    setResponseMessage(null);

    try {
      const response = await axios.get('http://localhost:3001/api/recharge', {
        params: {
          mobileNumber,
          planAmount,
          operator: operator.value,
          circle: circle.value,
        },
      });

      if (response.data) {
        setResponseMessage(`Recharge Status: ${response.data.Response}`);
      }
    } catch (error) {
      console.error('Error during recharge:', error);
      setResponseMessage('Error during recharge. Please try again.');
    }

    setLoading(false);
  };

  return (
    <PageWrapper>
      <FormWrapper>
        <Title>Mobile Recharge</Title>
        <Form onSubmit={handleSubmit}>
          <Label htmlFor="mobileNumber">Mobile Number</Label>
          <Input
            type="text"
            id="mobileNumber"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            required
          />

          <Label htmlFor="operator">Operator</Label>
          <Select
            id="operator"
            options={operators}
            value={operator}
            onChange={setOperator}
            placeholder="Select Operator"
            styles={customStyles}
            required
          />

          <Label htmlFor="planAmount">Plan Amount</Label>
          <Input
            type="text"
            id="planAmount"
            value={planAmount}
            onChange={(e) => setPlanAmount(e.target.value)}
            required
          />

          <Label htmlFor="circle">Circle</Label>
          <Select
            id="circle"
            options={circles}
            value={circle}
            onChange={setCircle}
            placeholder="Select Circle"
            styles={customStyles}
            required
          />

          <SubmitButton type="submit" disabled={loading}>
            {loading ? 'Processing...' : 'Recharge'}
          </SubmitButton>
        </Form>
        {responseMessage && <p>{responseMessage}</p>}
      </FormWrapper>
    </PageWrapper>
  );
};

export default MobileRecharge;
