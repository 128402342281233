const theme = {
    colors: {
      primary: '#0070f3',
      secondary: '#1c1c1c',
      background: '#f4f4f4',
    },
    breakpoints: {
      mobile: '768px',
    },
  };
  
  export default theme;
  