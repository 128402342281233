import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Styled components
const Container = styled.div`
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 2rem auto;
  width: 100%;
  @media (max-width: 768px) {
    padding: 1.5rem;
    margin: 1rem;
  }
`;

const Title = styled.h2`
  margin-bottom: 1.5rem;
  color: #333;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
`;

const Section = styled.div`
  margin-bottom: 2rem;
  padding: 1rem;
  border-radius: 8px;
  background: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Label = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 600;
  color: #555;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  margin-bottom: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    border-color: #2575fc;
    box-shadow: 0 0 0 3px rgba(37, 117, 252, 0.2);
    outline: none;
  }
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #2575fc;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #1d68d2;
  }

  &:active {
    background-color: #1a5bb8;
    transform: scale(0.98);
  }
`;

const Profile = () => {
  const [userDetails, setUserDetails] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
  });
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch('http://localhost:3001/api/profile', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // Attach the token
          },
        });

        const data = await response.json();

        if (response.ok) {
          setUserDetails({
            fullName: data.user.fullName,
            email: data.user.email,
            phoneNumber: data.user.mobileNumber,
          });
        } else {
          toast.error(data.message || 'Failed to fetch profile data');
        }
      } catch (error) {
        toast.error('An error occurred: ' + error.message);
      }
    };

    fetchProfile();
  }, []);


  const handlePasswordChange = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('http://localhost:3001/api/update-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Attach the token
        },
        body: JSON.stringify({
          mobileNumber: userDetails.phoneNumber,
          currentPassword,
          newPassword,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        toast.success('Password updated successfully');
        setCurrentPassword('');
        setNewPassword('');
      } else {
        toast.error(data.message || 'Failed to update password');
      }
    } catch (error) {
      toast.error('An error occurred: ' + error.message);
    }
  };
  
  return (
    <Container>
      <Title>Profile</Title>

      <Section>
        <h3>User Details</h3>
        <Label htmlFor="fullName">Full Name</Label>
        <Input
          type="text"
          id="fullName"
          value={userDetails.fullName}
          readOnly
        />

        <Label htmlFor="email">Email</Label>
        <Input
          type="email"
          id="email"
          value={userDetails.email}
          readOnly
        />

        <Label htmlFor="phoneNumber">Phone Number</Label>
        <Input
          type="text"
          id="phoneNumber"
          value={userDetails.phoneNumber}
          readOnly
        />
      </Section>

      <Section>
        <h3>Update Password</h3>
        <form onSubmit={handlePasswordChange}>
          <Label htmlFor="currentPassword">Current Password</Label>
          <Input
            type="password"
            id="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />

          <Label htmlFor="newPassword">New Password</Label>
          <Input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />

          <Button type="submit">Update Password</Button>
        </form>
      </Section>
      <ToastContainer />
    </Container>
  );
};

export default Profile;
