// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import theme from './styles/theme';
import Header from './components/Header';
import Footer from './components/Footer';
import MobileRecharge from './pages/MobileRecharge';
import DTHRecharge from './pages/DTHRecharge';
import AddMoney from './pages/AddMoney';
import RechargeHistory from './pages/RechargeHistory';
import WalletHistory from './pages/WalletHistory';
import Commission from './pages/Commission';
import Home from './pages/Home';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Signup from './pages/Signup';
import ProtectedRoute from './components/ProtectedRoute';

const Layout = ({ children }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  const isSignupPage = location.pathname === '/signup';

  return (
    <>
      {!isLoginPage && !isSignupPage && <Header />}
      <main>{children}</main>
      {!isLoginPage && !isSignupPage && <Footer />}
    </>
  );
};
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <GlobalStyles />
        <Routes>
          <Route path="/home" element={<Layout><Home /></Layout>} />
          <Route path="/mobile-recharge" element={<ProtectedRoute element={<Layout><MobileRecharge /></Layout>} />} />
          <Route path="/dth-recharge" element={<ProtectedRoute element={<Layout><DTHRecharge /></Layout>} />} />
          <Route path="/add-money" element={<ProtectedRoute element={<Layout><AddMoney /></Layout>} />} />
          <Route path="/recharge-history" element={<ProtectedRoute element={<Layout><RechargeHistory /></Layout>} />} />
          <Route path="/wallet-history" element={<ProtectedRoute element={<Layout><WalletHistory /></Layout>} />} />
          <Route path="/commission" element={<ProtectedRoute element={<Layout><Commission /></Layout>} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<ProtectedRoute element={<Layout><Profile /></Layout>} />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
