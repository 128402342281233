import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify'; // For displaying notifications
import 'react-toastify/dist/ReactToastify.css';
import styled, { keyframes } from 'styled-components';

// Background animation
const BackgroundAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Wrapper with same background gradient and animation
const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  background-size: 600% 600%;
  animation: ${BackgroundAnimation} 10s ease infinite, ${fadeIn} 1s ease-out;
  padding: 1rem;
  box-sizing: border-box;
`;

const LoginForm = styled.form`
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  animation: ${fadeIn} 1s ease-out;
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
  font-size: 1.8rem;
  font-weight: 600;
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: 500;
  color: #666;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.2s ease;
  
  &:focus {
    border-color: #2575fc;
    outline: none;
  }
`;

const SubmitButton = styled.button`
  padding: 0.75rem 1.25rem;
  background-color: #2575fc;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: #6a11cb;
  }
  
  &:active {
    background-color: #4a90e2;
  }
`;

const SignupLink = styled.div`
  margin-top: 1.5rem;
  text-align: center;
  font-size: 1rem;
  color: #666;

  a {
    color: #2575fc;
    text-decoration: none;
    font-weight: 500;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Login = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Hook for navigation

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('http://localhost:3001/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mobileNumber, password }),
      });
      
      const data = await response.json();
      
      if (response.ok) {
        toast.success('Login successful! Redirecting...');
        // Save the token to localStorage
        localStorage.setItem('token', data.token); // Updated to access token correctly
        setTimeout(() => {
          navigate('/home'); // Redirect to the home page
        }, 2000); // Delay to show success message
      } else {
        toast.error(data.message || 'Login failed');
      }
    } catch (error) {
      toast.error('An error occurred: ' + error.message);
    }
  };

  return (
    <LoginWrapper>
      <LoginForm onSubmit={handleSubmit}>
        <Title>Login</Title>
        <Label htmlFor="mobileNumber">Mobile Number</Label>
        <Input
          type="text"
          id="mobileNumber"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
          required
        />
        <Label htmlFor="password">Password</Label>
        <Input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <SubmitButton type="submit">Login</SubmitButton>
        <SignupLink>
          Don’t have an account? <Link to="/signup">Sign up here</Link>
        </SignupLink>
      </LoginForm>
      <ToastContainer /> {/* ToastContainer to display notifications */}
    </LoginWrapper>
  );
};

export default Login;
