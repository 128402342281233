// utils/auth.js
import { jwtDecode } from 'jwt-decode';

export const getToken = () => localStorage.getItem('token');

export const isAuthenticated = () => {
  const token = getToken();
  
  if (!token) {
    return false;
  }
  
  try {
    const decodedToken = jwtDecode(token);
    // Check token expiration
    return decodedToken.exp > Date.now() / 1000;
  } catch (e) {
    return false;
  }
};
