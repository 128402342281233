import React, { useState } from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto;
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
`;

const Label = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: #666;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  margin-bottom: 1rem;
  transition: border-color 0.2s ease;
  
  &:focus {
    border-color: #2575fc;
    outline: none;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #2575fc;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #6a11cb;
  }

  &:active {
    background-color: #4a90e2;
  }
`;

const AddMoney = () => {
  const [amount, setAmount] = useState('');

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleAddMoney = () => {
    if (amount) {
      // Handle add money logic here
      console.log(`Adding ${amount} to wallet.`);
      setAmount(''); // Clear input field after action
    } else {
      alert('Please enter an amount.');
    }
  };

  return (
    <Container>
      <Title>Add Money</Title>
      <Label htmlFor="amount">Enter Amount</Label>
      <Input
        type="number"
        id="amount"
        value={amount}
        onChange={handleAmountChange}
        placeholder="Enter amount"
        required
      />
      <SubmitButton onClick={handleAddMoney}>Add Money</SubmitButton>
    </Container>
  );
};

export default AddMoney;
